export enum Role {
  INTERNAL_TEACHERS = 'INTERNAL_TEACHERS',
  MANAGER_FREE_CLB = 'MANAGER_FREE_CLB',
  APPROVER_FREE_CLB = 'APPROVER_FREE_CLB',
  CLB_EXTERNAL_PARTICIPANT = 'CLB_EXTERNAL_PARTICIPANT',
  EXTERNAL_TEACHERS = 'EXTERNAL_TEACHERS',
  PARTICIPANT_FREE = 'PARTICIPANT_FREE',
  PAYER_FREE_CLB = 'PAYER_FREE_CLB',
  SUPERADMIN_SCHAARBEEK = 'SUPERADMIN_SCHAARBEEK',
  EMAGAZINE_ADMIN = 'EMAGAZINE_ADMIN',
  EMAGAZINE_READER = 'EMAGAZINE_READER',
  LOCAL_ADMIN = 'LOCAL_ADMIN',
  UNVERIFIED_USER = 'UNVERIFIED_USER',
  CITIZEN = 'CITIZEN'
}
