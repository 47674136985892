<div class="card-wrapper">
  <div class="header">
    <!-- Navbar brand -->
    <div class="flex-row">
      <a class="link-content" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36"/>
        </span>
        <h2 class="brand-text mb-0 title-width">{{ coreConfig.app.appTitle }}</h2>
      </a>
    </div>
    <!--/ Navbar brand -->
  </div>
  <div class="card card-additional-properties">
    <div class="card-body cb-w-80">
      <h1>{{'FILL_OUT_PROFILE'|translate}}</h1>
      <form [formGroup]="accountForm" class="validate-form">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="firstName">{{'FIRST_NAME'|translate}}</label>
              <div class="input-group">
                <input
                  id="firstName"
                  autofocus=""
                  class="form-control"
                  formControlName="firstName"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="name">{{'LAST_NAME'|translate}}</label>
              <div class="input-group form-password-toggle input-group-merge">
                <input
                  id="name"
                  autofocus=""
                  class="form-control"
                  formControlName="name"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="personEmail">{{'PERSON_EMAIL'|translate}}</label>
              <div class="input-group">
                <input
                  id="personEmail"
                  autofocus=""
                  class="form-control"
                  formControlName="personEmail"
                />
              </div>
            </div>
            <app-validation-message
              [inputControl]="accountForm.get('personEmail')"
              [submitted]="submitted" [controlName]="'PERSON_EMAIL'|translate">
            </app-validation-message>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="phoneNumber">{{'PHONE_NUMBER'|translate}}</label>
              <div class="input-group">
                <input
                  id="phoneNumber"
                  autofocus=""
                  class="form-control"
                  formControlName="phoneNumber"
                />
              </div>
            </div>
            <app-validation-message
              [inputControl]="accountForm.get('phoneNumber')"
              [submitted]="submitted" [controlName]="'PHONE_NUMBER'|translate">
            </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="accountEmail">{{'ACCOUNT_EMAIL'|translate}}</label>
              <div class="input-group">
                <input
                  id="accountEmail"
                  autofocus=""
                  class="form-control"
                  formControlName="accountEmail"
                />
              </div>
            </div>
            <app-validation-message
              [inputControl]="accountForm.get('accountEmail')"
              [submitted]="submitted" [controlName]="'ACCOUNT_EMAIL'|translate">
            </app-validation-message>
          </div>
          <div class="col-6">
            <h5 class="mb-0">{{'CONTACT_PREFERENCE'|translate}}</h5>
            <div class="form-check-inline">
              <div class="custom-control custom-checkbox mr-1"
                   placement="bottom"
                   [ngbTooltip]="'SMS_DISABLED_TOOLTIP'|translate"
                   [disableTooltip]="!userPhoneNumberMissing()">
                <input class="custom-control-input"
                       id="customCheckbox1"
                       name="customCheckbox"
                       type="checkbox"
                       [value]="'SMS'"
                       (change)="onContactPreferenceChange($event)"
                       [disabled]="userPhoneNumberMissing()"
                       [checked]="validateSMSOptionCheck()"
                />
                <label class="custom-control-label"
                       for="customCheckbox1">{{'SMS'|translate}}</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input"
                       id="customCheckbox2"
                       name="customCheckbox"
                       type="checkbox"
                       [value]="'EMAIL'"
                       (change)="onContactPreferenceChange($event)"
                       [checked]="accountForm.value.contactPreference.includes('EMAIL')"
                />
                <label class="custom-control-label"
                       for="customCheckbox2">{{'E-mail'|translate}}</label>
              </div>
            </div>
          </div>
        </div>

        <ng-container formGroupName="livingAddress">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="addressCity">{{'ADDRESS_CITY'|translate}}</label>
                <div class="input-group">
                  <input
                    id="addressCity"
                    autofocus=""
                    class="form-control"
                    formControlName="city"
                  />
                </div>
              </div>
              <app-validation-message
                [inputControl]="accountForm.get('livingAddress.city')"
                [submitted]="submitted" [controlName]="'ADDRESS_CITY'|translate">
              </app-validation-message>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="addressPostcode">{{'ADDRESS_POSTCODE'|translate}}</label>
                <div class="input-group">
                  <input
                    id="addressPostcode"
                    autofocus=""
                    class="form-control"
                    formControlName="postcode"
                  />
                </div>
              </div>
              <app-validation-message
                [inputControl]="accountForm.get('livingAddress.postcode')"
                [submitted]="submitted" [controlName]="'ADDRESS_POSTCODE'|translate">
              </app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="addressStreet">{{'ADDRESS_STREET'|translate}}</label>
                <div class="input-group">
                  <input
                    id="addressStreet"
                    autofocus=""
                    class="form-control"
                    formControlName="street"
                  />
                </div>
              </div>
              <app-validation-message
                [inputControl]="accountForm.get('livingAddress.street')"
                [submitted]="submitted" [controlName]="'ADDRESS_STREET'|translate">
              </app-validation-message>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="addressNumber">{{'ADDRESS_NUMBER'|translate}}</label>
                <div class="input-group">
                  <input
                    id="addressNumber"
                    autofocus=""
                    class="form-control"
                    formControlName="number"
                  />
                </div>
              </div>
              <app-validation-message
                [inputControl]="accountForm.get('livingAddress.number')"
                [submitted]="submitted" [controlName]="'ADDRESS_NUMBER'|translate">
              </app-validation-message>
            </div>
          </div>
        </ng-container>

        <div class="checkbox-outer">
          <input
            type="checkbox"
            id="is-billing-address"
            class="checkbox-decor"
            formControlName="isBillingSameAsLiving"
          />
          <label for="is-billing-address" class="checkbox-text">{{'BILLING_SAME_AS_ADDRESS' | translate}}</label>
        </div>

        <div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <app-multiple-select (selectedItems)="setFunctions($event)"
                                     [items]="['not correct', 'values']"
                                     [displayField]="'displayName'"
                                     [submitted]="submitted"
                                     [title]="'FUNCTION'"
                                     [searchable]="true"
                                     [hasError]="!!accountForm.get('function').errors"
                >
                </app-multiple-select>
              </div>
              <app-validation-message
                [inputControl]="accountForm.get('function')"
                [submitted]="submitted" [controlName]="'FUNCTION'|translate">
              </app-validation-message>
            </div>

            <ng-container formGroupName="billingAddress">
              <div class="col-6" *ngIf="!accountForm.get('isBillingSameAsLiving').value">
                <div class="form-group">
                  <label for="billingOrganizationName">{{'BILLING_ORGANISATION_NAME'|translate}}</label>
                  <div class="input-group">
                    <input
                      id="billingOrganizationName"
                      autofocus=""
                      class="form-control"
                      formControlName="organizationName"
                    />
                  </div>
                </div>
                <app-validation-message
                  [inputControl]="accountForm.get('billingAddress.organizationName')"
                  [submitted]="submitted" [controlName]="'BILLING_ORGANISATION_NAME'|translate">
                </app-validation-message>
              </div>
            </ng-container>
          </div>
        </div>

        <ng-container *ngIf="!accountForm.get('isBillingSameAsLiving').value" formGroupName="billingAddress">
          <div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="billingAddressCity">{{'BILLING_ADDRESS_CITY'|translate}}</label>
                  <div class="input-group">
                    <input
                      id="billingAddressCity"
                      autofocus=""
                      class="form-control"
                      formControlName="city"
                    />
                  </div>
                </div>
                <app-validation-message
                  [inputControl]="accountForm.get('billingAddress.city')"
                  [submitted]="submitted" [controlName]="'BILLING_ADDRESS_CITY'|translate">
                </app-validation-message>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="billingAddressPostcode">{{'BILLING_ADDRESS_POSTCODE'|translate}}</label>
                  <div class="input-group">
                    <input
                      id="billingAddressPostcode"
                      autofocus=""
                      class="form-control"
                      formControlName="postcode"
                    />
                  </div>
                </div>
                <app-validation-message
                  [inputControl]="accountForm.get('billingAddress.postcode')"
                  [submitted]="submitted" [controlName]="'BILLING_ADDRESS_POSTCODE'|translate">
                </app-validation-message>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="billingAddressStreet">{{'BILLING_ADDRESS_STREET'|translate}}</label>
                  <div class="input-group">
                    <input
                      id="billingAddressStreet"
                      autofocus=""
                      class="form-control"
                      formControlName="street"
                    />
                  </div>
                </div>
                <app-validation-message
                  [inputControl]="accountForm.get('billingAddress.street')"
                  [submitted]="submitted" [controlName]="'BILLING_ADDRESS_STREET'|translate">
                </app-validation-message>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="billingAddressNumber">{{'BILLING_ADDRESS_NUMBER'|translate}}</label>
                  <div class="input-group">
                    <input
                      id="billingAddressNumber"
                      autofocus=""
                      class="form-control"
                      formControlName="number"
                    />
                  </div>
                </div>
                <app-validation-message
                  [inputControl]="accountForm.get('billingAddress.number')"
                  [submitted]="submitted" [controlName]="'BILLING_ADDRESS_NUMBER'|translate">
                </app-validation-message>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="container-for-splitting">
          <div class="left-div">
            <div class="checkbox-outer">
              <input
                type="checkbox"
                id="privacy-statement"
                class="checkbox-decor"
                formControlName="privacyStatement"
              />
              <label for="privacy-statement" class="checkbox-text">{{'AGREE_WITH_PRIVACY_STATEMENT' | translate}}</label>
            </div>
          </div>

          <div class="right-div">
            <div class="checkbox-outer">
              <input
                type="checkbox"
                id="terms-statement"
                class="checkbox-decor"
                formControlName="privacyStatement"
              />
              <label for="terms-statement" class="checkbox-text">{{'AGREE_WITH_TERMS_STATEMENT' | translate}}</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 middle-button">
            <button *ngIf="!isAccountRegistered" (click)="saveAccount()" class="btn btn-primary mr-1 mt-1" rippleEffect
                    type="submit" tabindex="1">{{'SAVE_PROFILE'|translate}}
            </button>
            <button *ngIf="isAccountRegistered" (click)="editAccount()" class="btn btn-light mt-1" rippleEffect
                    tabindex="1">{{'EDIT_PROFILE'|translate}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
