import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {CourseFilterService} from './course-filter.service';

@Injectable({
  providedIn: 'root'
})
export class InitializationService {

  constructor(private authService: AuthService,
              private courseFilterService: CourseFilterService) {
  }

  initialize(): void {
      this.courseFilterService.initialize();
      if (this.authService.isLoggedIn()) {
        this.userLoggedInActions();
      }
  }

  userLoggedInActions(): void {
      if (this.authService.isUnverified())
          return;
      this.courseFilterService.loadUsersCourseFilter();
  }

}
