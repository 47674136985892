import {Component, Input, ViewEncapsulation} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-validation-message',
  templateUrl: './validation-message.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ValidationMessageComponent {

  @Input()
  inputControl: AbstractControl;

  @Input()
  submitted: boolean;

  @Input()
  controlName: string;

}
