import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreConfigService} from '../../../../@core/services/config.service';
import {ProfileService} from '../../../core/services/profile.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LocalStorageService} from '../../../core/services/local-storage.service';
import {AlertService} from '../../../core/services/alert.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-citizen-profile',
  templateUrl: './citizen-profile.component.html',
  styleUrls: ['./citizen-profile.component.scss']
})
export class CitizenProfileComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  isAccountRegistered: boolean = false;
  submitted: boolean = false;
  coreConfig: any;
  userId: number;

  private defaultAlertOptions = {
    closeButton: true,
    autoClose: true,
    keepAfterRouteChange: false
  };

  constructor(private _coreConfigService: CoreConfigService,
              private _formBuilder: FormBuilder,
              private profileService: ProfileService,
              private _localStorageService: LocalStorageService,
              private _router: Router,
              private alertService: AlertService,
              private translate: TranslateService) {
    this._coreConfigService.setConfig({
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    });
    this._unsubscribeAll = new Subject();
  }

  public accountForm: FormGroup = this._formBuilder.group({
    inss: ['', Validators.required],
    firstName: ['', Validators.required],
    name: ['', Validators.required],
    personEmail: ['', [Validators.required, Validators.email]],
    phoneNumber: ['', Validators.required],
    livingAddress: this._formBuilder.group({
      postcode: ['', Validators.required],
      city: ['', Validators.required],
      street: ['', Validators.required],
      number: ['', Validators.required],
    }),
    accountEmail: ['', [Validators.required, Validators.email]],
    isBillingSameAsLiving: [true],
    billingAddress: this._formBuilder.group({
      organizationName: [''],
      postcode: [''],
      city: [''],
      street: [''],
      number: [''],
    }),
    function: [''],
    privacyStatement: [false],
    termsStatement: [false],
    contactPreference: [[]]
  });

  ngOnInit(): void {
    this.profileService.fetchDataForUnverifiedUser().subscribe(unverifiedUser => {
      this.accountForm.patchValue({
        inss: unverifiedUser.inss,
        firstName: unverifiedUser.firstName,
        name: unverifiedUser.name
      });
    });

    this.accountForm.get('isBillingSameAsLiving')?.valueChanges.subscribe(isBillingAsAddress => {
      ['postcode', 'city', 'street', 'number', 'organizationName']
        .forEach(controlName => {
          const control = this.accountForm.get('billingAddress').get(controlName);
          control.setValidators(isBillingAsAddress ? [] : Validators.required);
          control.updateValueAndValidity();
        });
    });

    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
      this.coreConfig = config;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  saveAccount(): void {
    this.submitted = true;

    if (this.accountForm.valid) {
      let optionValidation = this.validateContactPreferences();
      this.profileService.citizenRegister(this.accountForm.value)
        .subscribe(userId => {
          this.profileService.userIdForCitizen.next(userId);
          if (optionValidation) {
            this.removeContactPreference('SMS');
            this.alertService.warn(this.translate.instant('CONTACT_PREFERENCE_ALERT'), this.defaultAlertOptions);
          } else {
            this.alertService.success(this.translate.instant('USER_INFO_SUCCESS_CHANGE'), this.defaultAlertOptions);
          }
          this._localStorageService.clearUserData();
          this._router.navigateByUrl('/verify-mail');
        });
    }
  }

  editAccount(): void {

  }

  setFunctions(functions: string[]) {
    this.accountForm.get('function').setValue(functions);
  }

  onContactPreferenceChange(event: any): void {
    const val = event.target.value;
    if (this.accountForm.value.contactPreference.includes(val)) {
      this.removeContactPreference(val);
    } else {
      if (val == 'EMAIL' || this.accountForm.value.phoneNumber != '') {
        const currentContactPreferences = this.accountForm.value.contactPreference;
        currentContactPreferences.push(val);
        this.accountForm.patchValue({
          contactPreference: currentContactPreferences
        });
      }
    }
  }

  validateSMSOptionCheck() {
    return this.accountForm.value.contactPreference.includes('SMS') && !this.userPhoneNumberMissing();
  }

  validateContactPreferences() {
    if (this.accountForm.value.contactPreference.includes('SMS') && this.userPhoneNumberMissing()) {
      this.removeContactPreference('SMS');
      return true;
    }
    return false;
  }

  userPhoneNumberMissing() {
    return this.accountForm.value.phoneNumber === '' || !this.accountForm.value.phoneNumber;
  }

  removeContactPreference(value) {
    this.accountForm.patchValue({
      contactPreference: this.accountForm.value.contactPreference.filter(ele => ele != value)
    });
  }
}
