import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guards/auth.guards';
import {Role} from './core/models/user/role';
import {CitizenProfileComponent} from './features/public/citizen-profile/citizen-profile.component';

const routes: Routes = [
  {
    path: 'verification',
    data: {roles: [Role.UNVERIFIED_USER]},
    component: CitizenProfileComponent
  },
  {
    path: '',
    loadChildren: () => import('./features/public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    data: {roles: [Role.APPROVER_FREE_CLB, Role.SUPERADMIN_SCHAARBEEK, Role.EMAGAZINE_ADMIN, Role.LOCAL_ADMIN]},
    loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'courses',
    data: {roles: [Role.PARTICIPANT_FREE, Role.CLB_EXTERNAL_PARTICIPANT]},
    loadChildren: () => import('./features/courses/courses.module').then(m => m.CoursesModule)
  },
  {
    path: 'posts',
    loadChildren: () => import('./features/participant/posts/posts.module').then(m => m.PostsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
